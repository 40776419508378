.ant-space,
.ant-col,
.ant-list-item {
  width: 100%;
}

.ant-btn {
  border-radius: 7px;
}

.ant-btn-primary {
  box-shadow: none !important;
}

.ant-btn svg {
  margin-bottom: 4px;
}

.ant-notification {
  top: 5.1rem !important;
}

/* .ant-layout-sider {
  width: 200px;
} */

.ant-layout-header {
  background-color: #ffffffee;
  padding: 0;
  position: fixed;
  z-index: 5;
  transition: 0.2s;
}

.ant-layout-header-full-width {
  width: 100%;
}

.ant-layout-header-calc-width {
  width: calc(100% - 200px);
}

@media (max-width: 576px) {
  /* .ant-layout-sider {
    width: 100%;
  } */

  /* .ant-layout-header-calc-width {
    width: 0;
  } */
}

/* .ant-layout-sider,
.ant-layout-sider .ant-menu-dark {
  background: #2f3136;
} */

.ant-layout-content {
  margin: 5.5rem 16px 0;
  overflow: initial;
  padding-bottom: 2rem;
}

.ant-menu-item {
  padding-left: 16px !important;
}

.ant-menu-submenu-title {
  padding-left: 16px !important;
}

.ant-steps-icon .anticon svg {
  margin-bottom: 0.2rem;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.anticon svg {
  margin-bottom: 4px;
}

.anticon-spin svg {
  margin: 0;
}

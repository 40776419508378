body {
  margin: 0;
  color: black;
  font-size: 16px;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-family: "Roboto";
}

.put-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.put-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-card {
  width: 450px;
}

@media (max-width: 576px) {
  .login-card {
    width: 100%;
  }
}

.bg-unread {
  background-color: #fff0b3;
}

textarea {
  resize: none;
}

img {
  pointer-events: none;
}

.clickable:hover {
  cursor: pointer;
}

.far {
  margin: 5px;
  margin-left: 0;
}

.fas {
  margin: 5px;
  margin-left: 0;
}

.fa {
  margin: 5px;
  margin-left: 0;
}

.color-primary {
  color: #467fcf;
}

.color-danger {
  color: #cc2900;
}

.color-warning {
  color: #ffc34d;
}

.color-danger-disabled {
  color: #ffd6cc;
}

.color-disabled {
  color: #cccccc;
}

.color-green {
  color: #00b300;
}

.color-safe {
  color: #7bd235;
}

.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

.hide-on-tablet {
  display: block;
}

.show-on-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .hide-on-tablet {
    display: none;
  }

  .show-on-tablet {
    display: block;
  }
}

@media (max-width: 576px) {
  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile {
    display: block !important;
  }
}
